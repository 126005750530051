@require '/src/dev'

/*


        Generic box model setup


*/
html
  box-sizing: border-box

*, *:before, *:after
  box-sizing: inherit
