
/*


		Usage: bp( {breakpoint1} [breakpoint(n)] )

		{breakpoints}
		480, 640, 768, 900, 990, 1235, 1300

		.sidebar
			color: red
			+bp(max480)
				color: green

		.sidebar
			color: red
			+bp(min460 max767)
				color: green

*/

// TUDO: Should this thing accept custom values?

bp(breakpoints)
	conditions = ()
	for breakpoint in breakpoints
		push(conditions, $media_queries[breakpoint])
	conditions = join("and ", conditions)
	@media conditions
		{block}
