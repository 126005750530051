$link
    &,
    &:link,
    &:visited
        cursor: pointer
        color: $color-link
        text-decoration: none
    &:hover,
    &:focus,
    &:active
        color: $color-link
        text-decoration: underline
        outline: none
$link--history
    &:visited,
    &:visited:hover
        color: $color-link-visited

$link--block
    &:hover,
    &:active,
    &:focus
        text-decoration: none

$link--dark
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active
        color: $color-link-secondary

$link--light
    &,
    &:link,
    &:visited
        color: $color-text-details
    &:hover,
    &:focus,
    &:active
        color: $color-link-secondary

$link--bright
    &,
    &:link,
    &:visited
        color: $milk
    &:hover,
    &:focus,
    &:active
        color: $milk

$link--nav
    a&,
    & a,
    a&:link,
    & a:link
    a&:visited
    & a:visited
        cursor: pointer
        color: $color-link-secondary
        text-decoration: none
    a&:hover,
    & a:hover,
    a&:active,
    & a:active
    a&:focus
    & a:focus
        text-decoration: none

