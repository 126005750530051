@require '/src/dev'

/*


        Very low level element styling, no classes allowed, keep this as short as possible, most things should be opt in.


*/

html
    font-size: 100%
body
    background-color: $milk
    // Always stretch body to fill the width, this makes the behaviour of the scrollbar a little nicer in some cases
    min-height: 100%          
    font-family: Finntype, Arial, Helvetica, sans-serif
    typo-size("base")
    color: $color-text 

body
    // Always show Scrollbar to prevent content jumping in certain situations (dynamic loading etc)
    overflow-y: scroll       
    // Insert hyphen and breaks a word instead of moving the whole word to next line
    hyphens: auto
    
button, input, optgroup, select, textarea
    font: inherit
    
h1, h2, h3, h4, h5, ul, ol, dl, p, blockquote
    margin: 0 0 8px 0

h1, h2, h3, h4, h5, img
    padding-bottom: 0px

h1,h2,h3,h4,h5
    font-weight: 300 // firefox translates normal to 400 other browsers 300
    
h1
    typo-size("t1")
    font-weight: bold

h2
    typo-size("t2")

h3
    typo-size("t3")

h4
    typo-size("t4")
    font-weight: bold

h5
    typo-size("t5")
    font-weight: bold

dt, dd
    margin: 0  16px

table
    font-size: inherit

input
    font-family: Finntype, Arial, Helvetica, sans-serif;
a
    @extend $link